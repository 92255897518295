<template>
  <fw-layout back-to="/" :loading="loading" :main-sidebar="false" loading-title="Apoio S+">
    <template #main-content>
      <div class="xl:max-w-screen-lg mx-auto px-5">
        <DashboardHero
          :title="call.title[language] || $t('notDefined')"
          :title-label="`${call.prefix}${call.code}`"
          :tag="$t(`callType.${call.type}`)"
          class="mt-2 mb-5"
          type="cover"
          background="exam-cover-abstract-02"
        >
          <template #toolbar>
            <div v-if="isOpenToApplications">
              <div class="inline-flex flex-shrink-0 text-primary relative gap-2">
                <div class="font-semibold">Candidaturas abertas</div>
                <fw-dot color="primary" inline class="mt-1" />
              </div>
            </div>
            <div v-else-if="isClosedToApplications">
              <div class="text-sm opacity-70 font-medium">
                Candidaturas encerradas
              </div>
            </div>
            <div v-else-if="isOpeningSoon">
              <div class="text-primary font-semibold">Brevemente</div>
            </div>
          </template>

          <template #default>
            <div class="flex justify-center mb-5">
              <div v-if="!call.application && call.validations.can_apply">
                <div>
                  <fw-button type="primary" size="lg" @click.native="startApplication">Iniciar candidatura</fw-button>
                </div>
              </div>
              <div v-else-if="call.application" class="text-white flex flex-col gap-2">
                <div>
                  <fw-button
                    type="white"
                    size="md"
                    @click.native="goToApplication(call.application.key, call.application.state)"
                  >
                    Ver candidatura
                  </fw-button>
                </div>
                <div class="text-xs opacity-80">Iniciada em {{ call.application.created_date | formatDateTime }}</div>
              </div>
            </div>
          </template>

          <template #footer>
            <div>
              <fw-label color="text-white opacity-50">{{ $t('startsAt') }}</fw-label>
              <div>{{ call.start_date | formatDate }}</div>
            </div>
            <div>
              <fw-label color="text-white opacity-50">{{ $t('endsAt') }}</fw-label>
              <div>{{ call.end_date | formatDate }}</div>
            </div>
            <div>
              <fw-label color="text-white opacity-50">Reservado a</fw-label>
              <div>Estudantes da Universidade de Coimbra</div>
            </div>
          </template>
        </DashboardHero>

        <fw-message
          v-if="!isStudent && (isOpenToApplications || isOpeningSoon)"
          type="warning"
          padding="p-3"
          class="text-sm text-center my-5"
          >Este procedimento está reservado a estudantes da Universidade de Coimbra e o seu perfil não cumpre este
          requisito.</fw-message
        >

        <fw-panel
          v-if="call.application && applicationStates[call.application.state]"
          title="A minha candidatura"
          featured
          boxed="xs"
          class="my-5"
        >
          <div class="flex px-4 pb-3 pt-1 w-full gap-12">
            <div class="flex-shrink-0 flex flex-col">
              <fw-label>Estado</fw-label>
              <div class="font-semibold flex-1 flex items-center">
                <fw-tag size="sm" :type="applicationStates[call.application.state].color" custom-class="text-sm">
                  {{ applicationStates[call.application.state].label[language] }}
                </fw-tag>
              </div>
            </div>
            <div class="flex-shrink-0 flex flex-col">
              <fw-label>Início</fw-label>
              <div class="font-medium flex-1 flex items-center">
                {{ call.application.created_date | formatDateTime }}
              </div>
            </div>
            <div class="flex-shrink-0 flex flex-col">
              <fw-label>Data de submissão</fw-label>
              <div v-if="call.application.submitted_date" class="font-medium flex-1 flex items-center">
                {{ call.application.submitted_date | formatDateTime }}
              </div>
              <div v-else class="font-medium opacity-50 flex-1 flex items-center">
                Não submetida.
              </div>
            </div>
            <div class="flex-1"></div>
            <div class="flex-shrink-0 flex flex-col justify-end">
              <div>
                <fw-button
                  type="border-light"
                  size="sm"
                  @click.native="goToApplication(call.application.key, call.application.state)"
                >
                  Ver candidatura
                </fw-button>
              </div>
            </div>
          </div>
        </fw-panel>

        <fw-panel v-if="call.description[language]" title="Sobre o procedimento" boxed="sm" custom-class="bg-white">
          <div class="content" v-html="call.description[language]" />
        </fw-panel>
      </div>
    </template>
  </fw-layout>
</template>

<script>
import DashboardHero from '@/fw-modules/fw-core-vue/ui/components/dashboard/DashboardHero'
import { APPLICATION_STATES } from '@/utils/index.js'

export default {
  components: {
    DashboardHero,
  },

  data() {
    return {
      loading: true,
      call: {},
      applicationStates: APPLICATION_STATES['scholarship'],
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },

    // Make sure exam is not allowed in small devices
    isMobileOrSmallTablet() {
      return window.innerWidth < 1024
    },
    callKey() {
      return this.$route.params.key
    },

    // Checks
    isRunning() {
      return !this.call.state.includes(['draft', 'closed'])
    },
    isOpenToApplications() {
      return this.call.time_to_end > 0 && this.call.time_to_start < 0 && this.call.state == 'published'
    },
    isClosedToApplications() {
      return this.call.time_to_end < 1
    },
    isOpeningSoon() {
      return this.call.time_to_start > 0 && this.call.state == 'published'
    },
    isStudent() {
      return this.call.validations.is_student
    },
  },

  mounted() {
    this.getcall()
  },

  methods: {
    async getcall() {
      this.loading = true
      try {
        const response = await this.api.getCall(this.callKey)
        console.log('getCall :>> ', response)
        this.call = response
      } catch (error) {
        console.log('Error getCall :>> ', error)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 750)
      }
    },

    goToApplication(key, state) {
      this.$router.push({
        name: 'application-view',
        params: {
          key: this.call.key,
          applicationKey: key,
          view: ['draft', 'canceled'].includes(state) ? 'metadata' : 'dashboard',
        },
      })
    },

    async startApplication() {
      console.log('startApplication :>> ')
      this.loading = true

      try {
        const response = await this.api.createApplication(this.callKey)
        console.log('createApplication :>> ', response)
        this.goToApplication(response.key, response.state)
      } catch (error) {
        console.log('Error createApplication :>> ', error)
        this.$buefy.snackbar.open({
          message: 'Ocorreu um erro ao tentar iniciar a candidatura.',
          type: 'is-danger',
        })
      }

      this.loading = false
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "callType": {
      "scholarship": "Apoio"
    },
    "notDefined": "Não definido",
    "prefix": "Prefixo",
    "createdAt": "Criado em",
    "startsAt": "Início das candidaturas",
    "endsAt": "Fim das candidaturas",
    "publishedOn": "Publicado em",
    "notFound": "Procedimento não encontrado"
  },
  "en": {
    "callType": {
      "scholarship": "Scholarship"
    },
    "notDefined": "Not defined",
    "prefix": "Prefix",
    "createdAt": "Created at",
    "startsAt": "Applications Start Date",
    "endsAt": "Applications End Date",
    "publishedOn": "Published on",
    "notFound": "call not found"
  }
}
</i18n>
